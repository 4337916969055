<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color:red"
          >コンテナー―削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status=='error'">
              <div
                v-for="msg of apierror.messages"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="3">
                <span style="color: black">コンテナーID</span>
              </v-col>
              <v-col cols="9">
                {{ selectedContainer.cntr_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span style="color: black">Container</span>
              </v-col>
              <v-col cols="9">
                {{ selectedContainer.cntr_no }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span style="color: black">Seal No.</span>
              </v-col>
              <v-col cols="9">
                {{ selectedContainer.seal_no }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span style="color: black">B/L No.</span>
              </v-col>
              <v-col cols="9">
                {{ selectedContainer.cntr_bl }}
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <v-data-table
                  ref="Contracts"
                  class="class-on-data-table elevation-1"
                  :headers="headers"
                  :items="selectedContainer.details"
                  fixed-header
                >
                  <template v-slot:[`item.cntr_dtl_weight`]="{ item }">
                    <label>{{ formatData(item.cntr_dtl_weight) }}</label>
                  </template>
                  <template v-slot:[`item.cntr_dtl_price`]="{ item }">
                    <label>{{ formatData(item.cntr_dtl_price) }}</label>
                  </template>
                  <template v-slot:[`item.cntr_dtl_amount`]="{ item }">
                    <label>{{ formatData(item.cntr_dtl_amount) }}</label>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="deleteStatus"
            @click="deleteItem(selectedContainer)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  props: ['icon', 'contId'],
  data: () => ({
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        value: 'disp_order',
        width: '5%',
        fixed: true,
      },
      {
        text: 'Pellet',
        align: 'left',
        sortable: false,
        value: 'pellet_id',
        width: '18%',
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        sortable: false,
        value: 'product_name',
        width: '36%',
        fixed: true,
      },
      {
        text: '重量(kg)',
        value: 'cntr_dtl_weight',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '単価',
        value: 'cntr_dtl_price',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '金額',
        value: 'cntr_dtl_amount',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
        fixed: true,
      },
    ],
    deleteStatus: false,
  }),
  computed: {
    ...mapState('containerStore', ['selectedContainer']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('containerStore', ['loadContainer', 'deleteContainer']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadContainer(this.contId)
        .catch(error => {
          this.apierror.status = 'error'
          if (error.response) {
            this.apierror.messages = error.response.data.message
          }

          // this.apierror.messages.push('削除できません。')
          console.log('apierror', this.apierror)
        }).finally(() => {
          console.log(this.selectedContainer)
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
    closeForm() {
      this.$emit('closeForm')
      this.apierror.messages = []
      this.dialog = false
    },
    deleteItem(selectedContainer) {
      this.apierror.messages = []
      this.deleteStatus = true
      this.deleteContainer(selectedContainer.cntr_id).then(() => {
        this.closeForm()
      }).catch(error => {
        this.deleteStatus = false
        this.apierror.status = 'error'
        if (error.response) {
          this.apierror.messages = error.response.data.message
        }

        console.log('apierror', this.apierror)
      }).finally(() => {
        this.deleteStatus = false
        this.$emit('closeForm')
      })
    },
  },

}
</script>
